import React from "react";
import "./facts.css";
import {
  FaPeopleCarry,
  FaMoneyBillAlt,
  FaMobileAlt,
  FaLaptopHouse,
  FaThumbsUp,
  FaClock,
} from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Facts = () => {
  return (
    <Container className="spacing">
      <Row>
        <Col className="text-center" sm={3}>
          <FaPeopleCarry className="imgfact" />
          <p className="facttext">Dedicated Developers</p>
        </Col>

        <Col className="text-center" sm={3}>
          <FaClock className="imgfact" />
          <p className="facttext">Timely Delivery</p>
        </Col>
        <Col className="text-center" sm={3}>
          <FaLaptopHouse className="imgfact" />
          <p className="facttext">2 Countries</p>
        </Col>
        <Col className="text-center" sm={3}>
          <FaThumbsUp className="imgfact" />
          <p className="facttext">Decade of success</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Facts;
